import React, { Suspense } from "react"
import { ModalContextProvider, Snackbar } from "@ggs/ui/common"
import { MuiThemeProvider } from "@ggs/styles"
import { StoreProvider, GlobalActionsDispatcher } from "@ggs/store"
import { I18nextProvider } from "react-i18next"
import { i18n } from "@ggs/i18n"
import { HeadProvider } from "react-head"
import SiteMetadataProvider from "./SiteMetadataProvider"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"

const queryClient = new QueryClient()

/**
 * @typedef {Object} RootProvidersProps
 * @property {JSX.Element} children
 */

/**
 * Composition of providers dependencies.
 * @param {RootProvidersProps} props
 * @return {JSX.Element}
 */
export default function RootProviders({ children }) {
  //const isSSR = typeof window === "undefined"
  const headTags = []
  return (
    <HeadProvider headTags={headTags}>
      <I18nextProvider i18n={i18n}>
        <StoreProvider>
          <GlobalActionsDispatcher>
            <MuiThemeProvider>
              <ModalContextProvider>
                <SiteMetadataProvider>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback="loading">{children}</Suspense>
                    {/* {children} */}
                    <Snackbar />
                  </QueryClientProvider>
                </SiteMetadataProvider>
              </ModalContextProvider>
            </MuiThemeProvider>
          </GlobalActionsDispatcher>
        </StoreProvider>
      </I18nextProvider>
    </HeadProvider>
  )
}
