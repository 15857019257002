import React, { useState } from "react"

export const ModalContext = React.createContext(null)

// @ts-ignore
export const ModalContextProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null)
  return (
    <ModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
