import React from "react"
import { Box, CircularProgress } from "@mui/material"

const ProgressIndicator = () => {
  return (
    <Box
      sx={{
        "padding": 2,
        ".MuiCircularProgress-svg": {
          height: "20px",
        },
      }}
    >
      <CircularProgress color="secondary" size={20} />
    </Box>
  )
}

export default ProgressIndicator
