import React, { useEffect } from "react"
import { Alert, IconButton } from "@mui/material"
import { icons } from "@ggs/ui"
import { clearNotification, useDispatch } from "@ggs/store"
import { useI18n } from "@ggs/i18n"

/**
 * @typedef {import('@ggs/store').NotificationMessage} AlertMessage
 * @typedef {import('@mui/material').AlertColor} AlertColor
 */

/**
 * @typedef {Object} SnackbarAlertProps
 * @property {AlertMessage} alert
 * @property {AlertColor} severity
 */

/**
 * Snackbar notification alert item.
 * @param {SnackbarAlertProps} props
 * @return {JSX.Element}
 */
const SnackbarAlert = ({ alert, severity }) => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const autoHideDuration = 12000

  useEffect(() => {
    setTimeout(() => {
      // Auto close after 6 seconds.
      dispatch(clearNotification(alert.id))
    }, autoHideDuration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert.id])

  /**
   * Handle single alert close.
   * @param {React.SyntheticEvent<any>} event User interaction event
   */
  const handleAlertClose = (event) => {
    dispatch(clearNotification(alert.id))
  }

  return (
    <Alert
      key={alert.id}
      severity={severity}
      sx={{
        mt: 2,
        display: "flex",
        alignItems: "center",
        "& .MuiAlert-message": {
          alignSelf: "center",
          display: "block",
          width: "100%",
        },
        "& .MuiAlert-action": {
          margin: "auto",
          padding: 0,
          position: "absolute",
        },
        "& .MuiIconButton-sizeSmall": {
          padding: 0,
        },
      }}
    >
      {alert.title}
      <IconButton
        className="snackbar__close"
        onClick={handleAlertClose}
        aria-label={t("global:aria.label.closeThisNotice")}
        size="large"
        color={severity}
      >
        {icons.CloseBlack}
      </IconButton>
    </Alert>
  )
}

export default SnackbarAlert
