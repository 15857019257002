import React from "react"

const icons = {
  SubmittedIcon: (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C3.58892 0 0 3.58862 0 8C0 12.4114 3.58892 16 8 16C12.4111 16 16 12.4114 16 8C16 3.58862 12.4111 0 8 0ZM12.46 5.332L7.53692 10.8705C7.41569 11.0068 7.24677 11.0769 7.07662 11.0769C6.94154 11.0769 6.80585 11.0326 6.69231 10.9422L3.61538 8.48062C3.35015 8.26862 3.30708 7.88123 3.51938 7.61569C3.73138 7.35015 4.11908 7.30708 4.38431 7.51938L7.00492 9.61569L11.54 4.51385C11.7652 4.25969 12.1548 4.23692 12.4086 4.46277C12.6628 4.68892 12.6858 5.07785 12.46 5.332Z" />
    </svg>
  ),
  WarningIcon: (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" />
    </svg>
  ),
  ErrorIcon: (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z" />
    </svg>
  ),
  InProgressIcon: (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z" />
    </svg>
  ),
  ChevronRight: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
    </svg>
  ),
  ChevronLeft: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
    </svg>
  ),
  ChevronDown: (
    <svg viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.111 5.84l-4.45-4.4C.446 1.23.446.886.661.673L1.18.16c.214-.212.562-.212.776 0L5.5 3.644 9.044.158c.214-.211.562-.21.776.001l.519.513c.215.213.215.557 0 .769l-4.45 4.4c-.215.212-.563.212-.778 0z"
        transform="translate(-393 -197) translate(250 185) translate(143 12)"
      />
    </svg>
  ),
  EditIcon: (
    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8766 1.80011L14.8781 4.80225L15.3787 4.30156C16.2074 3.47268 16.2074 2.12897 15.3787 1.30008C14.5497 0.470936 13.2055 0.470935 12.3766 1.30008L11.8766 1.80011Z" />
      <path d="M13.5001 6.17751L10.4986 3.17537L0.974293 12.7002L0 16.6768L3.97577 15.7023L13.5001 6.17751Z" />
    </svg>
  ),
  CloseBlue: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0.999999L11 11"
        stroke="#5A6EAC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999999 11L11 1"
        stroke="#5A6EAC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  CloseBlack: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0.999999L11 11"
        stroke="#2B3740"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999999 11L11 1"
        stroke="#2B3740"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  GreenCheckmark: (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.76471L5.15385 9L13 1" stroke="#0CAF1C" strokeWidth="2" />
    </svg>
  ),
  ModalClose: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0.999999L11 11"
        stroke="#5A6EAC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999999 11L11 1"
        stroke="#5A6EAC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  OffsiteLink: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8053 10.5231L13.6802 7.1306C13.3191 6.83361 12.75 7.07471 12.75 7.53605V9.32294C8.98526 9.36399 6 10.0826 6 13.4805C6 14.8519 6.92768 16.2106 7.95312 16.9209C8.27311 17.1426 8.72916 16.8644 8.61117 16.5051C7.54842 13.2682 9.11524 12.4088 12.75 12.359V14.3214C12.75 14.7835 13.3195 15.0235 13.6802 14.7269L17.8053 11.334C18.0647 11.1206 18.0651 10.7368 17.8053 10.5231Z"
        fill="#931723"
      />
    </svg>
  ),
  DownArrowBlue: (
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.95703 12.1009V2.76758"
        stroke="#0054B7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.87305 8.01758L7.95638 12.1009L12.0397 8.01758"
        stroke="#0054B7"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  ),
  Circle: (
    <svg
      width="234"
      height="189"
      viewBox="0 0 234 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <circle opacity="0.05" cx="117" cy="72" r="117" fill="#5A6EAC" />
        <circle opacity="0.05" cx="117" cy="72" r="82.5158" fill="#5A6EAC" />
        <circle opacity="0.05" cx="117" cy="72" r="45.5684" fill="#5A6EAC" />
      </g>
    </svg>
  ),
  Lock: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8V11H9V8Z"
        stroke="#5A6EAC"
        strokeWidth="2"
      />
      <path
        d="M6 12C6 10.8954 6.89543 10 8 10H16C17.1046 10 18 10.8954 18 12V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V12Z"
        fill="#5A6EAC"
      />
    </svg>
  ),
  Quote: (
    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3114 19.9858C23.3114 14.2159 24.7363 9.69869 27.586 6.43416C30.4748 3.16963 34.5152 1.02491 39.7072 0V5.92171C35.7644 7.09846 33.1098 9.2242 31.7435 12.2989C30.9628 13.9312 30.6505 15.5065 30.8066 17.0249H40V32H23.3114V19.9858ZM0 19.9858C0 14.2918 1.38584 9.79359 4.15752 6.4911C6.96823 3.18861 11.0477 1.02491 16.3958 0V5.92171C12.414 7.13642 9.73991 9.2242 8.37359 12.1851C7.67091 13.7414 7.37813 15.3547 7.49524 17.0249H16.6886V32H0V19.9858Z"
        fill="#D8DFF7"
      />
    </svg>
  ),
  Play: (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M32.5 23.134C33.1667 23.5189 33.1667 24.4811 32.5 24.866L20.5 31.7942C19.8333 32.1791 19 31.698 19 30.9282L19 17.0718C19 16.302 19.8333 15.8209 20.5 16.2058L32.5 23.134Z"
        fill="#931723"
      />
    </svg>
  ),
  ArrowRight: (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 15C5.94772 15 5.5 15.4477 5.5 16C5.5 16.5523 5.94772 17 6.5 17V15ZM27.2071 16.7071C27.5976 16.3166 27.5976 15.6834 27.2071 15.2929L20.8431 8.92893C20.4526 8.53841 19.8195 8.53841 19.4289 8.92893C19.0384 9.31946 19.0384 9.95262 19.4289 10.3431L25.0858 16L19.4289 21.6569C19.0384 22.0474 19.0384 22.6805 19.4289 23.0711C19.8195 23.4616 20.4526 23.4616 20.8431 23.0711L27.2071 16.7071ZM6.5 17L26.5 17V15L6.5 15V17Z"
        fill="#931723"
      />
    </svg>
  ),
  Information: (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#D8DFF7" />
      <circle cx="24" cy="24" r="21" fill="#D8DFF7" stroke="white" strokeWidth="2" />
      <path
        d="M26.4 16.8961V13.1521H21.856V16.8961H26.4ZM21.856 19.4561V36.0001H26.4V19.4561H21.856Z"
        fill="#5A6EAC"
      />
    </svg>
  ),
  InformationNavy: (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 16.4141C12.9112 16.4141 16.5 12.8253 16.5 8.41406C16.5 4.00284 12.9112 0.414062 8.5 0.414062C4.08877 0.414061 0.500001 4.00283 0.500001 8.41406C0.5 12.8253 4.08877 16.4141 8.5 16.4141ZM8.5 3.81241C8.94865 3.81241 9.31238 4.17614 9.31238 4.62479C9.31238 5.07344 8.94865 5.43717 8.5 5.43717C8.05135 5.43717 7.68762 5.07344 7.68762 4.62479C7.68762 4.17614 8.05135 3.81241 8.5 3.81241ZM7.68762 7.98263C7.68762 7.53398 8.05135 7.17025 8.5 7.17025C8.94865 7.17025 9.31238 7.53398 9.31238 7.98263L9.31238 12.2612C9.31238 12.7098 8.94865 13.0736 8.5 13.0736C8.05135 13.0736 7.68762 12.7098 7.68762 12.2612L7.68762 7.98263Z"
        fill="#223746"
      />
    </svg>
  ),
  AsideToggle: (
    <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="1.5" cy="8.5" r="1.5" />
      <circle cx="8.5" cy="1.5" r="1.5" />
      <circle cx="8.5" cy="8.5" r="1.5" />
    </svg>
  ),
  AsideClose: (
    <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_458_6502)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75388 0.246139C9.42569 -0.082046 8.8936 -0.0820466 8.56542 0.246139L5 3.81155L1.4346 0.246152C1.10642 -0.0820335 0.574325 -0.0820336 0.24614 0.246152C-0.0820461 0.574337 -0.0820467 1.10643 0.246139 1.43461L3.81154 5.00001L0.246159 8.56539C-0.0820269 8.89357 -0.082027 9.42566 0.246159 9.75385C0.574344 10.082 1.10644 10.082 1.43462 9.75385L5 6.18847L8.5654 9.75386C8.89358 10.082 9.42567 10.082 9.75386 9.75386C10.082 9.42568 10.082 8.89358 9.75386 8.5654L6.18847 5.00001L9.75388 1.4346C10.0821 1.10642 10.0821 0.574325 9.75388 0.246139Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_458_6502">
          <rect width="10" height="10" />
        </clipPath>
      </defs>
    </svg>
  ),
  Marker: (
    <svg width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4168 0C24.2833 0.125868 25.1612 0.199609 26.0137 0.383961C34.784 2.28215 40.5482 7.53428 43.1426 16.1111C44.6773 21.1852 44.0891 26.1742 41.8797 30.9839C40.0515 34.9621 37.4584 38.4063 34.5159 41.6076C31.4311 44.9641 27.9792 47.9328 24.5742 50.9473C23.0077 52.3344 21.0257 52.3573 19.4604 50.9778C15.466 47.4548 11.4194 43.9826 7.96241 39.9014C5.17111 36.606 2.76987 33.069 1.30498 28.9636C-0.716388 23.2996 -0.379705 17.7258 2.25913 12.377C5.42013 5.96792 10.5949 2.00117 17.5788 0.451345C18.5635 0.232665 19.5761 0.147482 20.5759 0C21.5225 0 22.469 0 23.4168 0Z"
      />
    </svg>
  ),
}

export default icons
