import React from "react"
import { CircularProgress, Stack, Typography } from "@mui/material"
import { useI18n } from "@ggs/i18n"

const Loader = () => {
  const { t } = useI18n()

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="loader"
    >
      <CircularProgress size={24} />
      <Typography sx={{ ml: 2 }}>{t("Loading...")}</Typography>
    </Stack>
  )
}

export default Loader
