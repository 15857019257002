import useDataObjectTransform from "./useDataObjectTransform"
import { SolrQueryBuilder } from "../services/solr/SolrQueryBuilder"
import { SolrApi } from "../services/solrApi"
import solrConfig from "../config/solr.json"
import dayjs from "dayjs"

/**
 * @typedef {import("../../components/GlobalAdBanners/Types").AdBanner} AdBanner
 * @typedef {import("../../components/LocationBanners/LocationBanners").BannerItem} LocationBanner
 */

const queryConfig = solrConfig.query
const clientConfig = solrConfig.server
const solrClient = new SolrApi(clientConfig)

/**
 * Hook to consume global ads solr index data and transform it into a UI component format.
 */
const useGlobalAdsSolrData = () => {
  const { runOfSiteAdsIndexToAdBanner, locationAdsIndexToLocationBanner } = useDataObjectTransform()
  const currentDateTime = dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]")

  /**
   * Get generic global ads not specific to location.
   * @return {Promise<LocationBanner[]>}
   */
  const getGenericGlobalAds = async () => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        "index_id": "global_ad_index",
        "sm_components_type": "run_of_site_ad",
        // Exclude country-specific and destination-specific ads.
        "-its_country_nid": "*",
        "-its_destinations_nid": "*",
        "ds_expiration_date": `[${currentDateTime} TO *]`,
      })
      .sort({
        id: "asc",
      })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return locationAdsIndexToLocationBanner(solrRecords)
  }

  /**
   * Get global ads for a given country nid.
   * @param {string} countryNid The country nid.
   * @return {Promise<AdBanner[]>}
   */
  const getCountryGlobalAds = async (countryNid) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "global_ad_index",
        sm_components_type: "trip_level_ad",
        itm_country_nid: countryNid,
        ds_expiration_date: `[${currentDateTime} TO *]`,
      })
      .sort({
        id: "asc",
      })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return runOfSiteAdsIndexToAdBanner(solrRecords)
  }

  /**
   * Get global ads for a given destination nid.
   * @param {string} destinationNid The destination nid.
   * @return {Promise<AdBanner[]>}
   */
  const getDestinationGlobalAds = async (destinationNid) => {
    const solrQuery = new SolrQueryBuilder(queryConfig)
    solrQuery
      .q({
        index_id: "global_ad_index",
        sm_components_type: "trip_level_ad",
        itm_destinations_nid: destinationNid,
        ds_expiration_date: `[${currentDateTime} TO *]`,
      })
      .sort({
        id: "asc",
      })

    const response = await solrClient.runQuery(solrQuery)
    const solrRecords = response?.response?.docs || null

    return runOfSiteAdsIndexToAdBanner(solrRecords)
  }

  return {
    getGenericGlobalAds,
    getCountryGlobalAds,
    getDestinationGlobalAds,
  }
}

export default useGlobalAdsSolrData
