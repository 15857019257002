import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
const debug = `${process.env.REACT_APP_I18N_DEBUG}`
const debugFlag = ["1", "yes", "true"].includes(debug)

/**
 * @typedef {import('i18next').InitOptions} InitOptions
 */

const resources = {
  en: {
    // global: globalEnglish,
  },
  fr: {
    // global: globalFrench,
  },
}

/**
 * @type {InitOptions}
 */
const initOptions = {
  resources,
  lng: "en",
  fallbackLng: "en",
  appendNamespaceToCIMode: true,
  saveMissing: false,
  debug: debugFlag,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
    prefix: "{{",
    suffix: "}}",
  },
  defaultNS: "components",
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["a", "br", "strong", "b", "i"],
  },
}

i18n.use(LanguageDetector).use(initReactI18next).init(initOptions).then(() => {
  console.log("i18n was initialized")
})

export default i18n
