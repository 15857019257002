const React = require("react")
const { RootProviders } = require("../../../src/local/wrappers")

/**
 * @typedef {Object} WrapRootElementProps
 * @property {JSX.Element} element
 */

/**
 * Wrap Gatsby root element with providers.
 * @param {WrapRootElementProps} props
 * @return {JSX.Element}
 */
const wrapRootElement = ({ element: children }) => {
  // @ts-ignore
  return <RootProviders>{children}</RootProviders>
}

module.exports = wrapRootElement
