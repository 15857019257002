import React, { useEffect } from "react"
import { useUserLocation } from "../../src/local/hooks"
import { setCountry, useDispatch, useSelector } from "@ggs/store"

/**
 * @typedef {Object} GlobalActionsDispatcherProps
 * @property {Element|JSX.Element} children
 */

/**
 * Component responsible of application global actions initialization.
 * @param {GlobalActionsDispatcherProps} props
 * @return {JSX.Element}
 */
export function GlobalActionsDispatcher({ children }) {
  const { getUserLocation } = useUserLocation()
  const dispatch = useDispatch()
  const countrySelVisible = useSelector((state) => state.settings.countrySelVisible)

  /**
   * Resolve user location and set country information at Redux.
   */
  const resolveUserLocation = async () => {
    const userLocation = await getUserLocation()
    if (userLocation) {
      dispatch(
        setCountry({
          name: userLocation.country || "Canada",
          code: userLocation.countryCode || "CA",
          isEurope: userLocation.isEurope || false,
        })
      )
    }
  }

  useEffect(() => {
    // Only resolve location from user if country selector is not visible, when
    // visible we should rely on the manually selected country.
    if (!countrySelVisible) {
      resolveUserLocation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}
