import { useTranslation, getI18n } from "react-i18next"
import { forEach, isString, noop } from "lodash"

/**
 * @typedef {import('./definitions').I18nHook} UseI18nHook
 */

/**
 * @typedef {Object} SelectOption
 * @property {string} label
 * @property {string} value
 */

/**
 * Internationalization hook, expose i18next function in SSR safe mode.
 * @return {UseI18nHook}
 */
export default function useI18n() {
  const { t } = useTranslation()
  const { language, languages, changeLanguage } = getI18n() || {
    language: "",
    changeLanguage: noop,
    languages: ["en"],
  }

  /**
   * Turn a list of keys into valid labels.
   * @param {String} target
   * @param {Object<string, any>} labels
   * @return {any}
   */
  const labelGroup = (target, labels) => {
    const translated = {}
    forEach(labels, (options, key) => {
      // @ts-ignore
      const { fallback, fill = {} } = options || {}
      const resolvedFallback = isString(fallback) ? t(fallback, fill) : fallback
      const labelKey = `${target}.${key}`
      // @ts-ignore
      translated[key] = t(labelKey) !== labelKey ? t(labelKey, fill) : resolvedFallback
    })

    return translated
  }

  /**
   * Map label string to a translation value.
   * @param {string} label
   * @param {Object<string, any>}options
   * @return {string} A translated string
   */
  function mapLabelToTranslation(label, options = {}) {
    return t(label, options)
  }

  /**
   * Map labels to translated select options.
   * @param {Array<string>} labels
   * @return {Array<SelectOption>} Labels translations
   */
  const mapLabelsToSelectOptions = (labels) => {
    return labels.map((label) => ({
      label,
      value: mapLabelToTranslation(label),
    }))
  }

  return {
    changeLanguage,
    labelGroup,
    language,
    languages,
    mapLabelToTranslation,
    mapLabelsToSelectOptions,
    t,
  }
}
