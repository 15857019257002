import React from "react"
import { Box, Snackbar as MuiSnackbar } from "@mui/material"
import { clearNotifications, useDispatch, useSelector } from "@ggs/store"
import { SnackbarAlert } from "@ggs/ui/common"
import { size } from "lodash"

/**
 * @typedef {import('@mui/material').SnackbarCloseReason} SnackbarCloseReason
 */

const Snackbar = () => {
  const alerts = useSelector((state) => state.ui.alerts)
  const dispatch = useDispatch()
  const vertical = "top"
  const horizontal = "center"
  const alertsCount = size(alerts.notifications)
  const hasAlerts = Boolean(alertsCount)

  /**
   * Handle Snackbar close.
   * @param {React.SyntheticEvent<any>} event User interaction event
   * @param {SnackbarCloseReason} reason Close reason
   */
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    dispatch(clearNotifications())
  }

  if (!hasAlerts) {
    return null
  }

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={hasAlerts}
      onClose={handleSnackbarClose}
      sx={{
        height: "100%",
        ".snackbar__close": {
          background: "none",
          border: "none",
          cursor: "pointer",
          position: "absolute",
          right: "5px",
          top: "50%",
          transform: "translateY(-50%)",
        },
        "& .MuiPaper-root": {
          position: "relative",
          width: {
            sm: "300px",
            md: " 600px",
          },
        },
      }}
    >
      <Box>
        {alerts.notifications.map((alert) => (
          <SnackbarAlert key={alert.id} alert={alert} severity={alert.type} />
        ))}
      </Box>
    </MuiSnackbar>
  )
}

export default Snackbar
