export const APP_STORE_KEY = "webapp"

/**
 * @typedef {Object} Geolocation
 * @property {number|null} latitude
 * @property {number|null} longitude
 * @property {number|null} accuracy
 */

/**
 * @typedef {Object} CountryInfo
 * @property {string} code
 * @property {string} name
 * @property {boolean} isEurope
 */

/**
 * @typedef {Object} StateSettings
 * @property {string} language User selected / resolved language code
 * @property {Geolocation} geolocation User geolocation (lat/lon)
 * @property {CountryInfo} country User located country information
 * @property {boolean} countrySelVisible Flag that indicates if country selector is visible
 * @property {Array<string>} clearedNotices Notices which were cleared
 */

/**
 * @typedef {Object} EntityOperation
 * @property {string} operationId Operation unique identifier
 * @property {string} operation Entity CRUD operation
 * @property {Object<string, any>} attributes Entity attributes
 */

/**
 * @typedef {Object} StateSession
 * @property {string} appVersion Loaded version of the webapp
 * @property {string} drupalToken Drupal JSON API token
 */

/**
 * @typedef {('success' | 'info' | 'warning' | 'error')} AlertType
 */

/**
 * @typedef {Object} AlertMessage
 * @property {string} id Alert unique ID
 * @property {string} code Alert code (identifier) to map with translatable strings
 * @property {string} title Alert message title
 * @property {string} body Alert message detailed description
 * @property {AlertType} type Alert type/style
 * @property {Boolean} visible Indicates if alert is visible
 */

/**
 * @typedef {('draft' | 'pending' | 'complete')} StepperStepStatus
 */

/**
 * @typedef {Object} StepperStepsMeta
 * @property {StepperStepStatus} status Step action status
 * @property {Array<number>} dependingSteps Index ID of the depending on steps
 */

/**
 * @typedef {Object} MapUI
 * @property {string} selectedResultId
 * @property {string} displayMode
 */

/**
 * @typedef {Object} Stepper
 * @property {number} currentStep Current (selected) step index
 * @property {number} stepsTotal Number of steps in the Stepper
 * @property {Object<number, StepperStepsMeta>} meta Step meta information index by step ID
 */

/**
 * @typedef {Object<string, Stepper>} SteppersUI
 */

/**
 * @typedef {Object} AlertsUI
 * @property {Array<AlertMessage>} notifications
 */

/**
 * @typedef {Object} SidebarUI
 * @property {Boolean} visible Flag that indicate if sidebar is visible or not
 * @property {string} contentType Indicate the content type used to resolve the sidebar content component
 */

/**
 * @typedef {Object} StateUI
 * @property {AlertsUI} alerts Alerts notification
 * @property {Boolean} loading Flag that indicates that Redux action is loading data
 * @property {SidebarUI} sidebar Sidebar drawer state
 * @property {SteppersUI} steppers Steppers state, we could have multiple steppers instances state.
 * @property {MapUI} map Map UI state
 */

/**
 * @typedef {Object<string, Object>} StateForms
 */

/**
 * @typedef {Object<string, any>} ContentIndex
 */

/**
 * @typedef {Object} DrupalNodes
 */

/**
 * @typedef {Object} StateViews
 * @property {any} wishlist
 * @property {ContentIndex} index
 */

/**
 * @typedef {Object} DefaultState
 * @property {StateSession} session User data related to a session
 * @property {StateSettings} settings User webapp settings
 * @property {StateUI} ui Data that is show through common UI components
 * @property {StateViews} views External data that support page views
 * @property {StateForms} forms Forms draft state
 */

/**
 * @type {DefaultState} initialState
 */
export const initialState = {
  forms: {},
  session: {
    appVersion: "",
    drupalToken: "",
  },
  settings: {
    language: "",
    geolocation: {
      latitude: null,
      longitude: null,
      accuracy: null,
    },
    country: {
      code: "CA",
      name: "Canada",
      isEurope: false,
    },
    countrySelVisible: false,
    clearedNotices: [],
  },
  ui: {
    alerts: {
      notifications: [],
    },
    sidebar: {
      visible: false,
      contentType: "cart",
    },
    steppers: {},
    loading: false,
    map: {
      selectedResultId: "",
      displayMode: "list",
    },
  },
  view: {
    wishlist: {},
    index: {},
  },
}
